
























































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import LogRocket from 'logrocket';
import ErrorAlert from '../Utility/ErrorAlert.vue';
import SuccessMessage from '../Utility/SuccessMessage.vue';
import SignupFormService from './services/signup-form.service';
import { debounce } from 'lodash';
import { SignUpService } from '@services/signup.service';

export default Vue.extend({
  name: 'SignupForm',
  components: {
    ErrorAlert,
    SuccessMessage
  },
  data() {
    return {
      formValid: false,
      signupFormService: null,
      eyeToggle: true,
      signupSuccess: false,
      signupErrorMessage: '',
      signupSuccessMessage: '',
      disableCompanyName: false, 
    };
  },
  props: {

  },
  computed: {
    passwordEyeIcon() {
      return this.eyeToggle ? 'mdi-eye' : 'mdi-eye-off';
    },
    passwordInputTypeToggle() {
      return this.eyeToggle ? 'password' : 'text';
    }
  },
  methods: {
    toggleEyeIcon() {
      this.eyeToggle = !this.eyeToggle;
    },
    /**
     * Validating the form this way ensures that are rules for passwords are met.
     */
    validateForm() {
      debounce(() => this.$refs.signupFormRef.validate(), 300)();
    },
    setupLogRocket() {
      if (window.location.host == 'app.searchadsmaven.com') {
        LogRocket.init('f59ovk/search-ads-maven', {
          network: {
            requestSanitizer: (request) => {
              ['onboard/save', 'auth/signup-ory', 'self-service/login'].forEach(segment => {
                if (request.url.toLowerCase().indexOf(segment) !== -1) {
                  request.body = null;
                }
              })
              return request;
            },
          },
        });
      }
    },
    async signupOry() {
      this.fullscreenLoader = this.$loading.show();
      try {
        const signupResponse = await SignUpService.signupOry(this.signupFormService.signupData);

        this._handleSignupResponse(signupResponse);
      } catch (error) {
        this._handleNonRecoverableError(error);
      }
      this.fullscreenLoader.hide();
    },
    _handleSignupResponse(response) {
      if (response.code == 0) return this._handleSignupSuccess(response);

      this._handleSignupError(response);
    },
    _handleSignupSuccess(response) {
      /** 
       * id will be present when a user is invited to a TEAM by team admin.
       * a verification is not needed in this case since they already clicked on the invitation link.
       */
      if (this.$route.query.id) this.signupSuccessMessage = 'Signup successful. Please login';
      else this.signupSuccessMessage = 'Please check your mail to verify and login';

      this.signupFormService.resetForm();
      this.signupSuccess = true;
    },
    _handleSignupError(response) {
      switch (response.code) {
        case 1:
        case 7:
          return this.signupErrorMessage = 'Email already exists. Please try login.';
        case 6:
          return this.signupErrorMessage = 'Weak password. Please try atleast 8 characters with 1 capital letter, 1 numeric and 2 special characters.';
        case 9:
          return this.signupErrorMessage = 'Registration is closed to public for now. Please come back later.';
        case 27:
          return this.signupErrorMessage = 'This email domain is already in use with an active account, please contact your company\'s Search Ads Maven administrator to be added to the account. Or, contact support@searchadsmaven.com';
        case 37:
          return this.signupErrorMessage = 'This email domain is not allowed.';
        default:
          return this._handleNonRecoverableError(response);
      }
    },
    _handleNonRecoverableError(error) {
      this.signupErrorMessage = 'Something went wrong! Please try later.';
    }
  },
  created() {
    this.setupLogRocket();

    this.signupFormService = new SignupFormService();
  },
  mounted() {
    const { id, companyName } = this.$route.query;
    if (id) {
      this.signupFormService.pasteEmail(id); // This sets the email and disables the email field
    }
    if (companyName) {
      const decodedCompanyName = decodeURIComponent(companyName); // Decode the company name from the URL
      this.signupFormService.signupData.CompanyName = decodedCompanyName; // Set the company name
      this.disableCompanyName = true; // Disable the company name field when populated
    }
  },
});
